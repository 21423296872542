<template>
  <form
    id="AccommodationFormform"
    class="limit p-3 p-md-4 pt-2"
    onsubmit="return false"
    :key="$route.name"
  >
    <div class="fs-3 mb-4 m-auto w-fit">
      {{ $route.name === "AccommodationUpdate" ? $tcust('AccommodationForm.title.update') : $tcust('AccommodationForm.title.new') }}
    </div>

    <CardContainer
      class="off-limit AccommodationForm-size m-auto h-fit w-100"
      :class="$store.state.licenseChosenType === 0 ? 'gradient' : 'gradient-group'"
      :impose-appearance="'List'"
    >
      <div class="w-100 d-flex flex-row flex-wrap p-0">

        <div
          class="inside-block w-100"
        >
          <div class="bg-box pb-2">
            <div class="ms-3 fs-5 pt-2 d-flex flex-row">
              {{ $tcust('ProfilUpdate.title.address') }}
            </div>
            <AddressInput
              class="p-3"
              :required="true"
              @dataChange="Address = $event"
              :fill="$store.state.accommodationChosen ? $store.state.accommodationChosen.Address : null"
            />

            <hr class="w-75 mx-auto mt-0" />

            <!-- BuildingYear -->
            <div class="d-flex flex-row align-items-end mb-2 ms-sm-3 px-4">
              <div class="col-6 col-sm-4 text-end">{{ $tcust('AccommodationForm.accommodation.buildingYear') }}</div>
              <div class="off-limit custom-input ms-3 align-self-center">
                <MDBInput
                  id="BuildingYear"
                  v-model="BuildingYear"
                  type="number"
                  class="text-center tiny"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row flex-wrap w-100">
          <div
            class="inside-block w-fit flex-grow-1"
          >
            <div class="bg-box pb-2 d-flex">
              <div class="custom-inside-size flex-grow-1 mt-0">
                <div class="ms-3 fs-5 pt-2 d-flex flex-row">
                  {{ $tcust('AccommodationForm.title.access') }}
                </div>
                <div
                  class="p-3 m-auto"
                >
                  <!-- Floor -->
                  <div class="custom-inside-size d-flex flex-row align-items-end mb-3 ms-4 ps-1">
                    <div class="text-end">{{ $tcust('AccommodationForm.accommodation.floor') }}</div>
                    <div class="w-50 off-limit custom-input ms-3">
                      <MDBInput
                        id="FloorNumber"
                        v-model="FloorNumber"
                        type="number"
                        class="text-center tiny"
                        required
                      />
                    </div>
                    <div
                      v-if="FloorNumber"
                      class="ms-2 text-nowrap overflow-visible"
                      v-html="$tcust('specific.floor', { number: FloorNumber }, true)"
                    /> 
                  </div>

                  <div class="custom-inside-size mb-3">
                    <MDBInput
                      id="AccessCode"
                      v-model="AccessCode"
                      type="text"
                      :label="$tcust('Profil.codes')"
                    />
                  </div>

                  <div class="custom-inside-size">
                    <MDBTextarea
                      id="Access"
                      rows="3"
                      v-model="Access"
                      type="textarea"
                      :label="$tcust('AccommodationForm.accommodation.access')"
                      class="text-center w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="inside-block w-fit flex-grow-1"
          >
            <div class="bg-box pb-2 d-flex">
              <div class="custom-inside-size flex-grow-1">
                <div class="ms-3 fs-5 pt-2 d-flex flex-row">
                  {{ $tcust('AccommodationForm.title.accommodationInfos') }}
                </div>
                <div class="p-3 w-100 m-auto">

                  <!-- Surface -->
                  <div class="d-flex flex-row align-items-end mb-2 px-4">
                    <div class="col-5 text-end">{{ $tcust('AccommodationForm.accommodation.surface') }}</div>
                    <div class="off-limit custom-input ms-3">
                      <MDBInput
                        id="Surface"
                        v-model="Surface"
                        type="number"
                        min="0"
                        class="text-center tiny"
                        required
                      />
                    </div>
                    <div class="ms-2">m²</div>
                  </div>

                  <!-- Type -->
                  <div class="d-flex flex-row align-items-end mb-2 px-4">
                    <div class="col-5 text-end">{{ $tcust('AccommodationForm.accommodation.type') }}</div>
                    <div class="off-limit custom-input ms-3">
                      <MDBInput
                        id="AccType"
                        v-model="AccType"
                        type="number"
                        min="0"
                        class="text-center tiny"
                        required
                      />
                    </div>
                  </div>

                  <!-- Door -->
                  <div class="d-flex flex-row align-items-end mb-2 px-4">
                    <div class="col-5 text-end">{{ $tcust('AccommodationForm.accommodation.doorNumber') }}</div>
                    <div class="off-limit custom-input ms-3 align-self-center">
                      <MDBInput
                        id="DoorNumber"
                        v-model="DoorNumber"
                        type="number"
                        class="text-center tiny"
                      />
                    </div>
                  </div>

                  <hr class="w-75 mx-auto my-3" />

                  <div class="mx-auto w-fit pe-5">
                    <MDBCheckbox
                      id="IsFurnished"
                      v-model="IsFurnished"
                      :label="$tcust('AccommodationForm.accommodation.isFurnished')"
                      class="my-1 ms-0"
                    /> 
                  </div>

                  <hr class="w-75 mx-auto my-3" />

                  <div class="custom-inside-size mb-3">
                    <MDBInput
                      id="Reference"
                      v-model="Reference"
                      type="text"
                      required
                      :label="$tcust('AccommodationForm.accommodation.reference')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardContainer>

    <!-- Submit button -->
    <div class="d-flex justify-content-center p-4 w-100 m-auto">
      <MDBBtn
        color="primary"
        block
        class="w-fit px-5 fw-bold"
        type="submit"
        @click="submit"
      >
        <span>{{ $tcust("common.form.validate") }}</span>
      </MDBBtn>
    </div>
  </form>
</template>

<script setup>
import { MDBBtn, MDBCheckbox, MDBTextarea, MDBInput } from "mdb-vue-ui-kit";
import AddressInput from "@/components/form/AddressInput.vue";

import { onMounted, onUpdated, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { mutateKey } from '@/helpers'

const router = useRouter();
const route = useRoute();
const store = useStore();

const Access = ref("");
const Address = ref("");
const Image = ref("");
const Surface = ref("");
const AccType = ref("");
const FloorNumber = ref("");
const DoorNumber = ref("");
const IsFurnished = ref(false);
const BuildingYear = ref("");
const Reference = ref("");

const data = reactive({
  Reference,
  LicenseKey: store.state.licenseChosen.LicenseKey,
  AccomodationCheckings: {
    Access,
    Address,
    Image,
    Surface,
    AccType,
    FloorNumber,
    DoorNumber,
    IsFurnished,
    BuildingYear,
  },
});

const updater = (key) => {
  if (store.state.accommodationChosen) return store.state.accommodationChosen[key]
  return null
};

onMounted(() => {
  if (route.name === 'AccommodationPost') {
    mutateKey('accommodationChosen', null)
  }
  if (route.name === "AccommodationUpdate") {
    Access.value = updater("Access");
    Address.value = updater("Address");
    Image.value = updater("Image");
    Surface.value = updater("Surface");
    AccType.value = updater("AccType");
    FloorNumber.value = updater("FloorNumber");
    DoorNumber.value = updater("DoorNumber");
    IsFurnished.value = updater("IsFurnished");
    BuildingYear.value = updater("BuildingYear");
  }
});

onUpdated(() => {
  if (route.name === 'AccommodationPost') {
    mutateKey('accommodationChosen', null)
  }
  Access.value = updater("Access");
  Address.value = updater("Address");
  Image.value = updater("Image");
  Surface.value = updater("Surface");
  AccType.value = updater("AccType");
  FloorNumber.value = updater("FloorNumber");
  DoorNumber.value = updater("DoorNumber");
  IsFurnished.value = updater("IsFurnished");
  BuildingYear.value = updater("BuildingYear");
});

const submit = async () => {
  if (!document.getElementById('AccommodationFormform').reportValidity()) return
  await store
    .dispatch("accomodationAddOrUpdate", data)
    .then(() => {
      if (!store.state.app.modal) {
        return router.push({
          name: "AccommodationView",
        });
      }
    })
};
</script>

<style lang="scss" scoped>
.gradient, .gradient-group {
  max-width: 700px !important;
  & .inside-block:not(.w-100) {
    min-width: 300px;
  }
}
.custom-inside-size {
  width: 250px;
  margin: auto;
}
.custom-inside-infos {
  width: 260px;
  margin: auto;
}
.custom-inside-infos-new {
  width: 330px !important;
  margin: auto;
  @media (max-width: 571.98px) {
    border: none !important;
    box-shadow: none !important;
  }
}
img {
  max-width: 250px;
  max-height: 250px;
}
.custom-input {
  min-width: 75px !important;
  max-width: 75px !important;
  & input {
    padding-right: 5px !important;
  }
}

.AccommodationForm-size {
  max-width: 95vw !important;
  @media (min-width: 572px) {
    max-width: 750px !important;
  }
}
@media (max-width: 359.98px) {
  .p-3, .p-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

#app.light-theme {
  .btn-outline-primary.text-normal {
    font-weight: normal !important;
  }
}
</style>
